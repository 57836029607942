body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body,
html,
#root {
  height: 100%;
}

#root {
  background-image: linear-gradient(0deg, #fff, #acf);
}

.saving-spinner {
  animation-name: saveButtonAnimation;
  animation-timing-function: linear;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes saveButtonAnimation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
